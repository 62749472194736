<template>
	<div class="login">
		<div class="loginForm bg-light shadow rounded border container">
			<h2>Login</h2>
			<input class="form-control" type="text" v-model="username" placeholder="Név" />
			<input class="form-control" type="password" v-model="password" placeholder="Jelszó" />
			<button class="btn btn-primary" type="button" v-on:click="login">Bejelentkezés</button>
		</div>
	</div>
</template>

<script>
import axios from "axios";

export default {
	name: 'LoginView',
	data() {
		return {
			username: null,
			password: null,
		}
	},
	methods: {
		login() {
			axios
				.post(this.$root.$data.apiUrl + this.$root.$data.apiUrlPath + "session/",
					{
						username: this.username,
						password: this.password
					}).then((response) => {
						if (response.status === 201) {
							axios.defaults.headers.common['X-CSRFToken'] = this.$cookies.get('csrftoken');
							axios
								.get(this.$root.$data.apiUrl + this.$root.$data.apiUrlPath + "session/").then((response) => {
									if (response.data.objects.length !== 0) {
										this.$root.$data.isLoggedin = true;
										this.$root.$data.userID = response.data.objects[0].id;
										this.$root.$data.employee = response.data.objects[0].employee;
										this.$router.push({ name: "Home" });
									}
								})
						}
					})
		}
	},
}
</script>

<style scoped>
.login {
	text-align: center;
}

.loginForm {
	/*margin-top: 12vh;*/
	width: 50vh;
	display: inline-block;
	padding: 4vh;
	position: fixed;
	left: 50%;
	top: 30%;
	transform: translate(-50%, -50%);
}

.loginForm h2 {
	margin-bottom: 2vh;
}

.loginForm input {
	margin: 1vh 0;
}
</style>
