import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import VueCookies from 'vue3-cookies'

import '@popperjs/core'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

import 'bootstrap-icons/font/bootstrap-icons.css'

const app = createApp(App);

app.use(router);

app.use(VueCookies);

const vm = app.mount('#app');

router.beforeEach((to, from, next) => {
	if (to.name !== 'Login' && vm.isLoggedin === false) next({ name: 'Login' })
	else next()
});
