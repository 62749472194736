<template>
	<div>
		<div class="mb-3">
			<button type="button" class="btn btn-primary" @click="prevMonth()"><i class="bi bi-chevron-left"></i></button>
			<span style="width: 120px; display: inline-block; font-size: 18px">{{actualDate.format('YYYY.MM')}}</span>
			<button type="button" class="btn btn-primary" @click="nextMonth()"><i class="bi bi-chevron-right"></i></button>
		</div>
		<div v-if="naptarData !== null">
			<Naptar :naptar="naptarData" :reload="this.getData"></Naptar>
			<Score :naptar="naptarData"></Score>
		</div>
	</div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { defineAsyncComponent } from "vue";

const Naptar = defineAsyncComponent(() => import("../components/naptar.vue"));
const Score = defineAsyncComponent(() => import("../components/score.vue"));

export default {
	name: 'HomeView',
	components: { Naptar, Score },
	data: () => {
		return {
			actualDate: null,

			dutywindow: null,
			days: null,

			naptarData: null,

			score: null,
		}
	},
	created() {
		this.actualDate = moment();

		this.getData();
	},
	methods: {
		getData() {
			let getDutywindow = axios.get(this.$root.$data.apiUrl + this.$root.$data.apiUrlPath + "dutywindow/?end__gte=" + moment(this.actualDate).startOf('month').format('YYYY-MM-DD') + "&start__lte=" + moment(this.actualDate).endOf('month').format('YYYY-MM-DD'));

			let getNaptar = axios.get(this.$root.$data.apiUrl + this.$root.$data.apiUrlPath + "naptar/?year=" + moment(this.actualDate).startOf('month').format('YYYY') + "&month=" + moment(this.actualDate).endOf('month').format('MM'));

			axios.all([
				getDutywindow,
				getNaptar
			]).then(axios.spread((dutywindowRes, naptarRes) => {
				this.dutywindow = dutywindowRes.data.objects;
				this.days = naptarRes.data.objects;
				this.processData();
			}));
		},
		processData() {
			this.naptarData = null;

			let renderData = [];

			let now = moment(this.actualDate);
			let lastDate = moment(now).endOf('month');
			let firstDate = moment(now).startOf('month');

			for (let i = moment(firstDate).startOf('isoWeek'); i.isBefore(moment(lastDate).endOf('isoWeek')); i.add(1, 'days')) {
				let date = moment(i);

				renderData.push({ 'date': date.format('YYYY-MM-DD') });

				if (moment().isSame(date, 'date')) {
					renderData.at(-1).isNow = true;
				} else if (moment().isAfter(date, 'date')) {
					renderData.at(-1).isPassed = true;
				}

				this.days.forEach(element => {
					if (moment(renderData.at(-1).date).isSame(moment(element.date))) {
						renderData.at(-1).isWeekend = element.datetype;
						renderData.at(-1).isHoliday = element.is_unnepnap;
					}
				});

				this.dutywindow.forEach(element => {
					if (date.isBetween(moment(element.start), moment(element.end), 'days', '[]') && date.isSameOrAfter(firstDate) && date.isSameOrBefore(lastDate)) {
						renderData.at(-1).data = element;
					}
				})
			}

			this.naptarData = renderData;
		},

		nextMonth() {
			this.actualDate.add(1, 'months');
			this.getData();
		},

		prevMonth() {
			this.actualDate.add(-1, 'months');
			this.getData();
		}
	},
}
</script>
