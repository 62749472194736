<template>
	<Sidebar v-if="this.isLoggedin" />
	<div class="page-content">
		<router-view />
	</div>
</template>

<script>
import axios from "axios";
import { defineAsyncComponent } from "vue";

const Sidebar = defineAsyncComponent(() => import("./components/sidebar.vue"));

export default {
	components: { Sidebar },
	data: () => {
		return {
			apiUrl: process.env.VUE_APP_API_URL,
			apiUrlPath: process.env.VUE_APP_API_PATH,

			isLoggedin: false,
			userID: null,
			employee: null,
		}
	},
	created() {
		axios.defaults.withCredentials = true;
		axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
		axios.defaults.headers.common['Content-Type'] = 'application/json';

		axios
			.get(this.$root.$data.apiUrl + this.$root.$data.apiUrlPath + "session/").then((response) => {
				if (response.data.objects.length !== 0) {
					axios.defaults.headers.common['X-CSRFToken'] = this.$cookies.get('csrftoken');
					this.isLoggedin = true;
					this.userID = response.data.objects[0].id;
					this.employee = response.data.objects[0].employee;
					this.$router.push({ name: "Home" });
				}
			})

	}
}
</script>

<style>
html,
body {
	height: 100%;
}

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;

	height: 100%;
	overflow: hidden;
}

.page-content {
	display: inline-block;
	width: 100%;
	padding: 20px;
	height: 100%;
	overflow: auto;
	transition: all 0.3s ease;
}

@media screen and (max-width: 600px) {
  .page-content {
    padding: 20px 0;
  }
}
</style>
