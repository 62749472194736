import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'

const routes = [
	{
		path: '/',
		name: 'Login',
		component: Login
	},
	{
		path: '/naptar',
		name: 'Home',
		component: Home
	},
]

const router = createRouter({
	history: createWebHashHistory(),
	linkActiveClass: 'active',
	routes
})

export default router
